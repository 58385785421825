<template>
  <div class="church-details template-2 convention-promoedit-wrapper">
    <div class="page-body container mx-lg">
      <div class="row">
        <form class="convention-promoedit">
          <b-row>
            <b-col sm="12">
              <div class="details section-top">
                <h2 class="main-this.blitz.">
                  {{ translations.tcAddBlitz }}
                </h2>
                <div class="form-group">
                  <h5 class="custom-form-label">{{ translations.tcEventTitle }}</h5>
                  <b-form-input
                    :title="translations.tcEventTitle"
                    id="event-name"
                    :placeholder="translations.tcEventTitle"
                    v-model="blitz.title"
                    required
                  ></b-form-input>
                </div>
                <hr class="divider mt-5 mb-5" />
                <div class="form-group">
                  <h5 class="custom-form-label">{{ translations.tcInternationalRep }}</h5>
                  <b-form-input
                    :title="translations.tcInternationalRep"
                    id="intl-rep"
                    :placeholder="translations.tcInternationalRep"
                    v-model="blitz.intl_rep"
                    required
                  ></b-form-input>
                </div>
                <div class="form-group mt-3">
                  <h5 class="custom-form-label">{{ translations.tcLocalContact }}</h5>
                  <span v-if="blitz.local_contact_name" class="mr-4">{{ blitz.local_contact_name }}</span>
                  <b-button
                    :name="`contactFindButton`"
                    variant="primary"
                    class="flex-1 btn-pl-xsm mt-2 mt-sm-0"
                    @click="showModal($event, 'personSearchModal')"
                    >{{ translations.tcFind }}
                  </b-button>
                </div>
                <hr class="divider mt-5 mb-5" />
                <div class="form-group mt-3">
                  <h5 class="custom-form-label">{{ translations.tcDate }}</h5>
                  <div id="dpContainer" class="dp-container">
                    <div id="dpStart" class="dp-startend">
                      <b-form-datepicker
                        id="start_date"
                        v-model="blitz.start_date"
                        class="mb-2 contact_date"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        :locale="prefCulture"
                        :placeholder="translations.tcStartDate"
                        :label-help="translations.tcCalHelp"
                        :label-no-date-selected="translations.tcNoDateSelected"
                        :show-decade-nav="showDecadeNav"
                        :hide-header="hideHeader"
                        size="sm"
                      >
                        <template #button-content style="padding: 12px">
                          <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                        </template>
                      </b-form-datepicker>
                    </div>
                    <div id="dpEnd" class="dp-startend">
                      <b-form-datepicker
                        id="end_date"
                        v-model="blitz.end_date"
                        class="mb-2 contact_date"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        :locale="prefCulture"
                        :placeholder="translations.tcEndDate"
                        :label-help="translations.tcCalHelp"
                        :label-no-date-selected="translations.tcNoDateSelected"
                        :show-decade-nav="showDecadeNav"
                        :hide-header="hideHeader"
                        size="sm"
                      >
                        <template #button-content style="padding: 12px">
                          <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                        </template>
                      </b-form-datepicker>
                    </div>
                  </div>
                </div>
                <hr class="divider mt-5 mb-5" />
                <div class="form-group">
                  <h5 class="custom-form-label">{{ translations.tcAttachment }}</h5>
                  <div class="table-item" v-if="blitz.attachment">
                    <div class="first-field">
                      <iPaperclip />
                      <a :href="blitz.attachment" class="ml-2" target="_blank">{{ translations.tcFlyer }}</a>
                    </div>
                    <div class="third-field docOptions ml-4">
                      <div @click="deleteExistingAttachment()">
                        <ServiceDelete />
                      </div>
                    </div>
                  </div>
                  <div v-if="!blitz.attachment" class="form-group mb-3">
                    <h5 class="custom-form-label mt-2">
                      {{ translations.tcFile }}
                    </h5>
                    <b-form-file
                      class="mb-1 mt-1 file-field"
                      style="text-transform: uppercase"
                      ref="fileUploadAttachment"
                      v-model.lazy="attachmentFile"
                      name="doc"
                      :browse-text="translations.tcBrowse"
                      :drop-placeholder="translations.tcDropFileHere"
                      :placeholder="translations.tcAttachment"
                      accept=".xls, .doc, .txt, .xlsx, .docx, .pdf"
                    />
                    <b-button @click="onAttachmentCancel" size="sm" class="ml-1 btn btn-tertiary" variant="tertiary">
                      {{ translations.tcClear }}
                    </b-button>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <b-button @click="handleSaveClick" variant="primary" size="sm" class="btn btn-primary">
                    {{ translations.tcSave }}
                  </b-button>
                  <b-button @click="handleCancelClick" size="sm" class="ml-1 btn btn-tertiary" variant="tertiary">
                    {{ translations.tcCancel }}
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </form>
      </div>
    </div>
    <PersonSearch @selectionMade="applyFoundPerson($event)" :camp_country="this.camp_country"></PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { calendarMixin } from '@/mixins/calendarMixin'
import constantData from '@/json/data.json'
import iPaperclip from '@/assets/svgs/iPaperclip.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import ServiceDelete from '@/assets/svgs/service-card-delete.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-blitz',
  mixins: [translationMixin, calendarMixin],
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      translations: {},
      blitz: {
        blz_key: null,
        title: '',
        intl_rep: '',
        local_contact_ind_key: null,
        local_contact_name: '',
        start_date: null,
        end_date: null,
        attachment: null,
      },
      camp_country: constantData.USA_country,
      findButton: '',
      showAttachmentUpload: false,
      attachmentFile: null,
      uploadAttachment: null,
    }
  },
  methods: {
    ...mapActions({
      addBlitz: 'usaScriptureBlitz/addBlitz',
      loadBlitzInfo: 'usaScriptureBlitz/loadBlitzInfo',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedUSABlitzKey: 'user/setSelectedUSABlitzKey',
    }),
    async page_load() {
      if (this.userSelectedBlitzKey) {
        await this.loadBlitzInfo(this.userSelectedBlitzKey)
        ;(this.blitz.blz_key = this.usa_blitz_info.blz_key),
          (this.blitz.title = this.usa_blitz_info.blz_name),
          (this.blitz.intl_rep = this.usa_blitz_info.blz_intl_rep),
          (this.blitz.local_contact_ind_key = this.usa_blitz_info.blz_local_contact_ind_key),
          (this.blitz.local_contact_name = this.usa_blitz_info.vin_complete_name),
          (this.blitz.start_date = this.formatForDatePicker(this.usa_blitz_info.blz_start_date)),
          (this.blitz.end_date = this.formatForDatePicker(this.usa_blitz_info.blz_end_date)),
          (this.blitz.attachment = this.usa_blitz_info.blz_attachment)
      }
    },
    async deleteExistingAttachment() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: this.translations.tcCancel,
        confirmButtonText: this.translations.tcOk,
        title: this.translations.tcDeleteThisAttachment,
        html: `<i>${this.translations.tcIsThatOk}</i>`,
      }).then((result) => {
        if (!!result.value) {
          this.blitz.attachment = null
        }
      })
    },
    async handleShowAttachmentForm() {
      this.showAttachmentUpload = !this.showAttachmentUpload
    },
    onAttachmentCancel() {
      this.showAttachmentUpload = false
      ;(this.attachmentFile = null), (this.uploadAttachment = null)
    },
    async applyFoundPerson(evt) {
      this.blitz.local_contact_name = evt.name_text
      this.blitz.local_contact_ind_key = evt.ind_key
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    async handleSaveClick() {
      let response = null

      if (this.validateForm()) {
        let blitzPost = this.prepBlitz()
        if (this.attachmentFile) {
          await (blitzPost.attachments = this.attachmentFile)
        }

        await Promise.all([this.setLoadingStatus(true), (response = await this.addBlitz(blitzPost))]).then(() => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: response ? 'success' : 'error',
            text: response
              ? !this.userSelectedBlitzKey
                ? this.translations.tcAlertAdded
                : this.translations.tcAlertEdited
              : this.translations.tcAlertError,
            confirmButtonText: this.translations.tcOk,
          }).then((result) => {
            this.setLoadingStatus(false)
            if (response) {
              this.setSelectedUSABlitzKey('')
              this.$router.push({ name: 'manage-blitzes' })
            }
          })
        })
      }
    },
    validateForm() {
      let rtn = true
      if (
        !this.blitz.title ||
        !this.blitz.intl_rep ||
        !this.blitz.start_date ||
        !this.blitz.end_date ||
        !this.blitz.local_contact_ind_key
      ) {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.translations.tcValidationError,
          confirmButtonText: this.translations.tcOk,
        })
      }
      return rtn
    },
    prepBlitz() {
      let preppedBlitz = {
        blz_key: this.blitz.blz_key ? this.blitz.blz_key : null,
        blz_name: this.blitz.title,
        blz_intl_rep: this.blitz.intl_rep,
        blz_local_contact_ind_key: this.blitz.local_contact_ind_key,
        blz_start_date: this.blitz.start_date,
        blz_end_date: this.blitz.end_date,
        blz_attachment: this.blitz.attachment ? this.blitz.attachment : null,
        blz_delete_flag: false,
        attachments: null,
      }

      return preppedBlitz
    },
    async handleCancelClick() {
      this.setSelectedUSABlitzKey('')
      this.$router.push({ name: 'manage-blitzes' })
    },
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
      userSelectedBlitzKey: 'user/userSelectedBlitzKey',
      usa_blitz_info: 'usaScriptureBlitz/usa_blitz_info',
    }),
  },
  async created() {
    await Promise.all([this.getViewTranslations()])

    await this.page_load()
  },
  components: {
    iPaperclip: iPaperclip,
    PersonSearch: PersonSearch,
    ServiceDelete: ServiceDelete,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/views/ConventionPromoEdit.scss';

.convention-promoedit {
  width: 100%;
}

.divider {
  background-color: #ccc;
}

.table-item {
  display: flex;
  justify-content: flex-start;
  .docOptions {
    svg {
      cursor: pointer;
    }
  }
}

.file-field {
  width: 50%;
  @include breakpoint(sm) {
    width: 100%;
  }
}

.dp-container {
  display: flex;
  width: 50%;
  @include breakpoint(sm) {
    width: 100%;
  }
  .dp-startend {
    width: 50%;
    @include breakpoint(sm) {
      width: 100%;
    }
  }
}

.contact_date {
  width: 100%;
  @include breakpoint(sm) {
    width: 100%;
  }
}
</style>
